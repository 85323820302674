export default { 
    BASE_URL:"https://api.freshymeat.com/api/v1/",
    // BASE_URL:"http://192.168.207.188:8085/api/v1/",
    // BASE_URL:"http://103.159.239.209:8085/api/v1/",
    END_POINT: {
        login: "loginwithotp",
        verify: "verify",
        banner: "banner",
        menus: "menus"
    }
}