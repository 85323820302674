import React from 'react'
import Breadcrumb from 'src/components/Breadcrumb'

const Cart = () => {
  return (
    <div>
      <Breadcrumb title="My Cart" />

      <div class="m-2">
        <div class="max-w-md mx-auto m-5 sm:p-5 bg-gray-100 rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="flex items-center justify-around pl-3 sm:pl-3">
            <div>
              <img src="./image/cart-truck.png" className="w-20" />
            </div>
            <div>
              <h1 class="sm:text-lg font-normal sm:px-5 px-2">
                Free Delivery on orders of ₹599 & Above (T&C Apply)
              </h1>
            </div>
          </div>
        </div>
        <div class="max-w-md mx-auto m-5 p-5 bg-gray-200 rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <h1 class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">
            Kimma cut fresh
          </h1>
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start">
              <h5 class="text-blue-900 text-lg font-medium m-1"> 1 Kg </h5>
              <p class=" text-gray-900 font-medium text-xl m-3">
                ₹ 360 <del class="text-sm text-red-500"> 380 </del>
              </p>
            </div>

            <div class="flex items-center justify-end">
              <div class="inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="toolbar">
                <button
                  type="button"
                  class="rounded-l inline-block px-4 py-2 bg-orange-500 text-white font-medium text-xs leading-tight uppercase hover:bg-orange-600 focus:bg-orange-400 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-600 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <button
                  type="button"
                  class=" inline-block px-4 py-2 bg-orange-500 text-white font-medium text-sm leading-tight uppercase "
                >
                  1
                </button>
                <button
                  type="button"
                  class=" rounded-r inline-block px-4 py-2 bg-orange-500 text-white font-medium text-xs leading-tight uppercase hover:bg-orange-600 focus:bg-orange-400 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-600 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="max-w-md mx-auto m-5 rounded-xl shadow-md overflow-hidden md:max-w-2xl border border-dotted px-4 border-gray-400 p-4">
          <h1 class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">
            Bill Details
          </h1>
          <div class="flex items-center justify-between">
            <h1 class="text-blue-900 text-lg font-medium m-1">Sub-Total</h1>
            <p class="items-center justify-end text-gray-900 font-medium text-xl m-1">₹ 360</p>
          </div>
          <div class="flex items-center justify-between">
            <h5 class="text-blue-900 text-lg font-medium m-1">Delivery charges</h5>
            <p class="items-center justify-end text-gray-900 font-medium text-xl m-1">₹ 70</p>
          </div>
          <hr class="my-2 border-gray-400"></hr>
          <div class="flex items-center justify-between">
            <h5 class="text-blue-900 text-lg font-medium m-1">Total</h5>
            <p class="items-center justify-end text-gray-900 font-medium text-xl m-1">₹ 460</p>
          </div>

          <a href='/checkout' class="flex items-center justify-end  pt-10 pb-3 ">

            <button
              type="button"
              class="  px-6 py-2.5 bg-green-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-900 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              <i class="fa fa-lock"></i> CHECKOUT
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Cart
