import React from 'react'

const Privacy = () => {
  return (
    <div style={{ justifyContent: 'center', margin: 50 }}>
      <h1>Privacy Policy</h1>

      <p>
        Freshymeat built the Freshymeat app as a Free app. This SERVICE is provided by Freshymeat at
        no cost and is intended for use as is. This page is used to inform visitors regarding our
        policies with the collection, use, and disclosure of Personal Information if anyone decided
        to use our Service. If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information that we collect is
        used for providing and improving the Service. We will not use or share your information with
        anyone except as described in this Privacy Policy. The terms used in this Privacy Policy
        have the same meanings as in our Terms and Conditions, which are accessible at Freshymeat
        unless otherwise defined in this Privacy Policy.
      </p>

      <h2>Information Collection and Use</h2>

      <p>
        For a better experience, while using our Service, we may require you to provide us with
        certain personally identifiable information, including but not limited to Name, IP, email
        Address, Phone number. The information that we request will be retained on your device and
        is not collected by us in any way. The app does use third-party services that may collect
        information used to identify you.
      </p>

      <h3>Link to the privacy policy of third-party service providers used by the app:</h3>
      <ul>
        <li>
          <a href="link_to_google_play_services_privacy_policy">Google Play Services</a>
        </li>
      </ul>

      <h2>Log Data</h2>

      <p>
        We want to inform you that whenever you use our Service, in the case of an error in the app
        we collect data and information (through third-party products) on your phone called Log
        Data. This Log Data may include information such as your device Internet Protocol (“IP”)
        address, device name, operating system version, the configuration of the app when utilizing
        our Service, the time and date of your use of the Service, and other statistics.
      </p>

      <h2>Service Providers</h2>

      <p>We may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>

      <p>
        We want to inform users of this Service that these third parties have access to their
        Personal Information. The reason is to perform the tasks assigned to them on our behalf.
        However, they are obligated not to disclose or use the information for any other purpose.
      </p>

      <h2>Security</h2>

      <p>
        We value your trust in providing us your Personal Information, thus we are striving to use
        commercially acceptable means of protecting it. But remember that no method of transmission
        over the internet, or method of electronic storage is 100% secure and reliable, and we
        cannot guarantee its absolute security.
      </p>

      <h2>Links to Other Sites</h2>

      <p>
        This Service may contain links to other sites. If you click on a third-party link, you will
        be directed to that site. Note that these external sites are not operated by us. Therefore,
        we strongly advise you to review the Privacy Policy of these websites. We have no control
        over and assume no responsibility for the content, privacy policies, or practices of any
        third-party sites or services.
      </p>

      <h2>Children’s Privacy</h2>

      <p>
        These Services do not address anyone under the age of 18. We do not knowingly collect
        personally identifiable information from children under 18 years of age. In the case we
        discover that a child under 18 has provided us with personal information, we immediately
        delete this from our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so that we will be able
        to do the necessary actions.
      </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this
        page periodically for any changes. We will notify you of any changes by posting the new
        Privacy Policy on this page. This policy is effective as of 2024-07-10.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or suggestions about our Privacy Policy, do not hesitate to
        contact us at <a href="mailto:freshymeatapp@gmail.com">freshymeatapp@gmail.com</a>.
      </p>

      <h1>Terms & Conditions</h1>

      <p>
        By downloading or using the app, these terms will automatically apply to you – you should
        make sure therefore that you read them carefully before using the app. You’re not allowed to
        copy or modify the app, any part of the app, or our trademarks in any way. You’re not
        allowed to attempt to extract the source code of the app, and you also shouldn’t try to
        translate the app into other languages or make derivative versions. The app itself, and all
        the trademarks, copyright, database rights, and other intellectual property rights related
        to it, still belong to Freshymeat.
      </p>

      <p>
        Freshymeat is committed to ensuring that the app is as useful and efficient as possible. For
        that reason, we reserve the right to make changes to the app or to charge for its services,
        at any time and for any reason. We will never charge you for the app or its services without
        making it very clear to you exactly what you’re paying for.
      </p>

      <p>
        The Freshymeat app stores and processes personal data that you have provided to us, to
        provide our Service. It’s your responsibility to keep your phone and access to the app
        secure. We therefore recommend that you do not jailbreak or root your phone, which is the
        process of removing software restrictions and limitations imposed by the official operating
        system of your device. It could make your phone vulnerable to malware/viruses/malicious
        programs, compromise your phone’s security features and it could mean that the Freshymeat
        app won’t work properly or at all.
      </p>

      <h2>Third-Party Services</h2>
      <p>The app does use third-party services that declare their Terms and Conditions.</p>
      <h3>Link to Terms and Conditions of third-party service providers used by the app:</h3>
      <ul>
        <li>
          <a href="link_to_google_play_services_terms">Google Play Services</a>
        </li>
      </ul>

      <p>
        You should be aware that there are certain things that Freshymeat will not take
        responsibility for. Certain functions of the app will require the app to have an active
        internet connection. The connection can be Wi-Fi or provided by your mobile network
        provider, but Freshymeat cannot take responsibility for the app not working at full
        functionality if you don’t have access to Wi-Fi, and you don’t have any of your data
        allowance left.
      </p>

      <h2>Changes to This Terms and Conditions</h2>

      <p>
        We may update our Terms and Conditions from time to time. Thus, you are advised to review
        this page periodically for any changes. We will notify you of any changes by posting the new
        Terms and Conditions on this page. These terms and conditions are effective as of
        2024-07-10.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or suggestions about our Terms and Conditions, do not hesitate to
        contact us at <a href="mailto:freshymeatapp@gmail.com">freshymeatapp@gmail.com</a>.
      </p>
    </div>
  )
}

export default Privacy
