import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
// import Badge from '@material-ui/core/Badge'
// import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
// import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
// import RemoveIcon from '@material-ui/icons/Remove'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
// import TextField from '@material-ui/core/TextField'
// import Drawer from '@material-ui/core/Drawer'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from 'src/redux/reducer/loginwithotp'
import { verifyUser } from 'src/redux/reducer/verifyotp'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const Header = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.login)

  const [USERID, setUSERID] = useState('')

  useEffect(() => {
    const value = localStorage.getItem('USERID')
    if (value) {
      setUSERID(value)
    }
  }, [])

  const [openlogin, setOpenlogin] = useState(false)

  const handleClickOpenlogin = () => {
    setOpenlogin(true)
  }
  const handleCloselogin = () => {
    setOpenlogin(false)
  }

  const [formData, setFormData] = useState({
    mobile_no: '',
    name: '',
  })

  // console.log(otp.join(''))

  const handleLogin = () => {
    const body = {
      mobile_no: formData.mobile_no,
      name: formData.name,
    }
    dispatch(loginUser(body))
  }

  const handleVerify = () => {
    const body = {
      mobile_no: formData.mobile_no,
      mobile_otp: otp.join(''),
    }
    dispatch(verifyUser(body))
  }

  const [otp, setOtp] = useState(['', '', '', ''])
  const inputRefs = useRef([])

  const handleChange = (value, index) => {
    if (value.length > 1) return // Prevent more than one digit in a single input

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    // Move focus to the next input field
    if (value && index < 3) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus()
    }
  }

  return (
    <>
      <div className="z-50 md:block sticky top-0 bg-white drop-shadow-xl border-b-2 border-gray-100">
        <div className="max-w-7xl mx-auto px-2 sm:px-2">
          <div className="flex justify-between items-center py-2 md:justify-start md:space-x-10">
            <div className="justify-start">
              <Link to="/">
                <img className="h-16 w-auto" src="./image/logo.png" alt="logo" />
              </Link>
            </div>

            <div className="flex text-cyan-700">
              <Link to="#" className="hover:text-gray-600" data-modal-toggle="large-modal">
                <i className="fas fa-map-marker-alt absolute text-xs sm:text-lg"></i>
                <span className="text-md sm:text-lg sm:pl-5 pl-3">
                  Select City <i className="fas fa-caret-down"></i>
                </span>
              </Link>
            </div>

            <div className="hidden sm:block justify-start sm:pl-44">
              <div className="lg:w-96">
                <div className="input-group relative flex flex-wrap items-stretch">
                  <input
                    type="search"
                    className="for /m-control relative flex-auto min-w-0 block w-full pl-10 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-red-200 focus:outline-none"
                    placeholder=" Search"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                  />
                  <i className="fas fa-search absolute pl-3 pt-3 text-gray-400"> </i>
                </div>
              </div>
            </div>

            {/* <div className="sm:pl-10">
                <button
                  type="button"
                  className="text-sm sm:text-lg bg-red-400 rounded-md p-2 inline-flex items-end justify-end text-white hover:text-red-600 hover:bg-gray-200 hover:outline-rose-500 focus:ring-2 focus:ring-inset focus:ring-gray-200 focus:text-gray-900"
                >
                  Party Plan
                </button>
              </div> */}

            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
              <div className="flex text-cyan-700 text-2xl">
                <Link to="/cart" className="hover:text-gray-600 px-10">
                  <i className="fas fa-shopping-cart absolute pl-15 pt-1 sm:640px"></i>
                  <span className="absolute text-xs ml-6 text-white px-1 bg-orange-500 rounded-full">
                    10
                  </span>
                </Link>
                {USERID ? (
                  <Link
                    to="/profile"
                    className="hover:text-gray-600 sm:px-10 px-3"
                    data-modal-toggle="example2"
                    data-modal-action="open"
                  >
                    <span className="sm:text-lg hidden sm:inline">Account</span>
                    <i className="fas fa-user sm:pl-2"></i>
                  </Link>
                ) : (
                  <Link
                    to="/"
                    className="hover:text-gray-600 sm:px-10 px-3"
                    data-modal-toggle="example2"
                    data-modal-action="open"
                    onClick={handleClickOpenlogin}
                  >
                    <span className="sm:text-lg hidden sm:inline">Login</span>
                    <i className="fas fa-sign-in-alt sm:pl-2"></i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Large Modal --> */}
      <div
        className="hidden bg-gray-700 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full"
        id="large-modal"
      >
        <div className="relative px-4 w-full max-w-4xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-10">
            <div className="flex justify-between items-center px-5 py-2 rounded-t border-b dark:border-gray-600">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">Choose Location</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="large-modal"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-1 text-center justify-between text-gray-600 hover:text-gray-800">
              <div className="sm:w-4/6 z-50 relative mx-auto">
                <div className="bg-white w-full rounded-xl shadow-lg">
                  <input
                    type="text"
                    placeholder="Search your city"
                    className="w-full h-10 text-sm rounded-md focus:outline-none"
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-4 grid-cols-2 gap-4 pt-10">
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city active">
                    <input
                      type="hidden"
                      name="ordercity"
                      id="1"
                      autoComplete="off"
                      defaultChecked=""
                    />
                    Nagpur
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="2" autoComplete="off" /> Wardha
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="3" autoComplete="off" /> Amaravati
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="4" autoComplete="off" /> Chandrapur
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="5" autoComplete="off" /> Gadchiroli
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="6" autoComplete="off" /> Akola
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="7" autoComplete="off" /> Gondia
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="11" autoComplete="off" /> Jalgaon
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="12" autoComplete="off" /> Nashik
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="13" autoComplete="off" /> Pune
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="14" autoComplete="off" /> Mumbai
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="15" autoComplete="off" /> Aurangabad
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="16" autoComplete="off" /> Amravati
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="17" autoComplete="off" /> Thane
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="18" autoComplete="off" /> Solapur
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="19" autoComplete="off" /> Ahmednagar
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="20" autoComplete="off" /> Navsari
                  </label>
                </div>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className="your_choose_city ">
                    <input type="hidden" name="ordercity" id="22" autoComplete="off" /> Indore
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        onClose={handleCloselogin}
        className="dailog-login max-w-md m-auto"
        aria-labelledby="customized-dialog-title"
        open={openlogin}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloselogin}>
          Login
        </DialogTitle>
        <DialogContent dividers>
          <div className="text-center">
            <h4 className="py-3">Login with Mobile number</h4>
            <div className="center text-center items-center justify-center content-center">
              <Grid
                container
                spacing={4}
                alignItems="flex-end"
                className="center text-center items-center justify-center content-center"
              >
                <Grid item className="flex items-center">
                  <PhoneAndroidIcon />
                  <input
                    className="block w-full rounded-md border-0 mx-2 py-1.5  pr-20 text-gray-900 ring-1 ring-inset ring-gray-300
                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter Mobile No"
                    type="number"
                    name="mobile_no"
                    id="mobile_no"
                    value={formData.mobile_no}
                    onChange={(e) => setFormData({ ...formData, mobile_no: e.target.value })}
                  ></input>
                </Grid>
              </Grid>
              {user?.registration === false && (
                <Grid
                  container
                  spacing={4}
                  alignItems="flex-end"
                  className="center text-center items-center justify-center content-center"
                >
                  <Grid item className="flex items-center">
                    <AccountCircle />
                    <input
                      className="block w-full rounded-md border-0 mx-2 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter Your Name"
                      type="text"
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    ></input>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>

          <div className="form-otp text-center">
            {user?.registration && (
              <>
                <h4 className="p-3 text-xl">Enter OTP</h4>
                <Grid item>
                  <div className="flex items-center justify-center gap-3">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text" // Use text type for better compatibility
                        className="w-14 h-14 text-center text-2xl font-Bold700 text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <p className="text-xs m-2">Resend Code ( in 30sec )</p>
                </Grid>
              </>
            )}

            <div className="center my-6">
              <Button
                className="btn"
                variant="contained"
                color="primary"
                onClick={() => {
                  user?.registration ? handleVerify() : handleLogin()
                }}
              >
                {user?.registration ? 'Verify' : 'Send Otp'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Header
