import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { thunk } from 'redux-thunk'
import loginReducer from './reducer/loginwithotp'
import bannerReducer from './reducer/getBanner'
import menusReducer from './reducer/getMenus'


const rootReducer = combineReducers({
  login: loginReducer,
  banner: bannerReducer,
  menus: menusReducer
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
export default store