import React, { useEffect } from 'react'

const RedirectComponent = () => {
  // useEffect(() => {
  //   window.location.href = 'https://play.google.com/store/apps/details?id=com.freshymeat'
  // }, [])

  function redirectToAppOrStore() {
    var urlParams = new URLSearchParams(window.location.search)
    var idParam = urlParams.get('id')

    // Construct the full URL with parameters for the app
    // var appUrl = 'freshymeat://LiveZikr?id=' + idParam
    var appUrl = 'freshymeat://'

    // Try opening the app using its custom URL scheme
    https: window.location.href = appUrl

    // Set a timeout to redirect to the Play Store if the app is not opened
    setTimeout(function () {
      // If the app is not opened, redirect to the Play Store with parameters
      var storeUrl = 'https://play.google.com/store/apps/details?id=com.freshymeat'
      window.location.href = storeUrl
    }, 1000) // Adjust the timeout as needed
  }

  return (window.onload = redirectToAppOrStore)
}

export default RedirectComponent
