import './App.css'
import './index.css'
import React, { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './common/Header'
import SearchFooter from './components/SearchFooter'
import Footer from './common/Footer'
import Home from './pages/Home'
import Catalog from './pages/Catalog'
import Profile from './pages/Profile'
import AddressBook from './pages/AddressBook'
import Order from './pages/Order'
import ContactUs from './pages/ContactUs'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermCondition from './pages/TermCondition'
import BulkEnquiry from './pages/BulkEnquiry'
import Blogs from './pages/Blogs'
import Search from './components/Search'
import History from './pages/History'
import AddressForm from './components/AddressForm'
import Product from './pages/Product'
import RecipeBook from './pages/RecipeBook'
import Checkout from './pages/Checkout'
import Cart from './pages/Cart'
import OrderSummary from './components/OrderSummary'
import DeletionRequest from './pages/privacy/DeletionRequest'
import Privacy from './pages/privacy/PrivacyPolicy'
import RedirectComponent from './components/RedirectComponent'

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<RedirectComponent />} />
          {/* <Route path="/" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/product" element={<Product />} />
          <Route path="/recipe-book" element={<RecipeBook />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/address-book" element={<AddressBook />} />
          <Route path="/order" element={<Order />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-and-condition" element={<TermCondition />} />
          <Route path="/bulk-enquiry" element={<BulkEnquiry />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/search" element={<Search />} />
          <Route path="/order-history" element={<History />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/addressform" element={<AddressForm />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/OrderSummary" element={<OrderSummary />} /> */}
          <Route path="/deleted" element={<DeletionRequest />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        {/* <SearchFooter /> */}
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  )
}

export default App
