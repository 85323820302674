import React from 'react'

const DeletionRequest = () => {
  return (
    <>
      <div style={{ justifyContent: 'center', textAlign: 'center', marginTop: 50 }}>
        <h2>Data Deletion Request</h2>
        <p>Please note below email address to request the deletion of your data.</p>
        <p>freshymeatapp@gmail.com</p>
        <p>Contact us : </p>
        <p>+91 855 484 3519</p>
      </div>
    </>
  )
}

export default DeletionRequest
